import { documentReady } from "./utils/dom"

documentReady(() => {
  const configElement = document.querySelector("#js-chat")
  if (!configElement) {
    return
  }
  const userConfig = configElement.dataset

  const slice = [].slice
  // prettier-ignore
  ZammadChat.prototype.T = function () { // eslint-disable-line func-names
    let item, items, j, len, string, translations
    ;(string = arguments[0]), (items = 2 <= arguments.length ? slice.call(arguments, 1) : [])
    if (this.options.lang) {
      if (!this.translations[this.options.lang]) {
        this.log.notice("Translation '" + this.options.lang + "' needed!")
      } else {
        translations = this.translations[this.options.lang]
        if (!translations[string]) {
          this.log.notice("Translation needed for '" + string + "'")
        }
        string = translations[string] || string
      }
    }
    if (items) {
      for (j = 0, len = items.length; j < len; j++) {
        item = items[j]
        string = string.replace(/%s/, item)
      }
    }
    return string
  }

  ZammadChat.prototype.translations = {
    de: {
      "<strong>Chat</strong> with us!": "<strong>Chatte</strong> mit uns!",
      "Scroll down to see new messages": "Scrolle nach unten um neue Nachrichten zu sehen",
      Online: "Online",
      Offline: "Offline",
      Connecting: "Verbinden",
      "Connection re-established": "Verbindung wiederhergestellt",
      Today: "Heute",
      Send: "Senden",
      "Chat closed by %s": "Chat beendet von %s",
      "Compose your message…": "Deine Nachricht…",
      "All colleagues are busy.": "Alle Kollegen sind belegt.",
      "You are on waiting list position <strong>%s</strong>.":
        "Du bist in der Warteliste an der Position <strong>%s</strong>.",
      "Start new conversation": "Neue Konversation starten",
      "Since you didn't respond in the last %s minutes your conversation with <strong>%s</strong> got closed.":
        "Da du in den letzten %s Minuten nichts geschrieben haben wurde deine Konversation mit <strong>%s</strong> geschlossen.",
      "Since you didn't respond in the last %s minutes your conversation got closed.":
        "Da du in den letzten %s Minuten nichts geschrieben hast wurde deine Konversation geschlossen.",
      "We are sorry, it takes longer as expected to get an empty slot. Please try again later or send us an email. Thank you!":
        "Es tut uns leid, es dauert länger als erwartet, um einen freien Platz zu erhalten. Bitte versuche es zu einem späteren Zeitpunkt noch einmal oder schicke uns eine E-Mail an service@machtfit.de. Vielen Dank!",
    },
    en: {
      "<strong>Chat</strong> with us!": "<strong>Chat</strong> with us!",
      "Scroll down to see new messages": "Scroll down to see new messages",
      Online: "Online",
      Offline: "Offline",
      Connecting: "Connecting",
      "Connection re-established": "Connection re-established",
      Today: "Today",
      Send: "Send",
      "Chat closed by %s": "Chat closed by %s",
      "Compose your message…": "Compose your message…",
      "All colleagues are busy.": "All colleagues are busy.",
      "You are on waiting list position <strong>%s</strong>.":
        "You are on waiting list position <strong>%s</strong>.",
      "Start new conversation": "Start new conversation",
      "Since you didn't respond in the last %s minutes your conversation with <strong>%s</strong> got closed.":
        "Since you didn't respond in the last %s minutes your conversation with <strong>%s</strong> got closed.",
      "Since you didn't respond in the last %s minutes your conversation got closed.":
        "Since you didn't respond in the last %s minutes your conversation got closed.",
      "We are sorry, it takes longer as expected to get an empty slot. Please try again later or send us an email. Thank you!":
        "We are sorry, it takes longer as expected to get an empty slot. Please try again later or send us an email to service@machtfit.de. Thank you!",
    },
  }

  const chat = new ZammadChat({
    fontSize: "12px",
    background: userConfig.color,
    flat: true,
    chatId: 1,
    idleTimeout: 3,
    waitingListTimeout: 10,
    inactiveTimeout: 10,
  })

  chat.onConnectionEstablished = (data) => {
    ZammadChat.prototype.onConnectionEstablished.call(chat, data)

    chat.send("chat_session_notice", {
      session_id: chat.sessionId,
      message: userConfig.email,
    })
  }
})
